<template>
  <div id="chat">
      
    <div id="header-chat" class="row" style="margin-bottom: 0.25em;">
      <div class="d-none d-lg-block col-lg-6">
          <strong>Post links to your videos here</strong> <small>(They will stay for 30 days)</small>  
      </div>
      
      <div class="col-6 col-lg-3" style="padding-right: 2px;">
        <select id="roomSelector" v-model="roomID" class="form-select" @change="changeRoom($event.target.value)">
          
          <template v-for="(room, index) in roomOptions" :key="index">
              <option v-if="isRegion(room)" :value="room" class="region">{{ room }}</option>
              <option v-else="isRegion(room)" :value="room" class="country">- {{ room }}</option>
          </template>

        </select> 
      </div>

      <!-- Topic Selector -->
      <div class="col-6 col-lg-3" style="padding-left: 2px;">
        <select id="topicSelector" v-model="topicID" class="form-select" @change="changeTopic($event.target.value)">
          <option v-for="topic in topicOptions" :key="topic" :value="topic">{{ topic }}</option>
        </select>
      </div>

      <div class="col-12 d-lg-none" style="margin-top: 0.25em;">
        <div class="row">
          <div class="col-6" style="padding-right: 2px;">
            <button id="showLinks" class="btn btn-primary w-100" @click="showLinks()">Links</button>
          </div>
          <div class="col-6" style="padding-left: 2px;">
            <button id="showChat" class="btn btn-primary w-100" @click="showChat()">Chat</button>
          </div>
        </div>
      </div> 

    </div>

    <div id="panels" class="row">

        <div id="links-panel" class="col-lg-6">
          <div id="link-input" class="input-container">
            <!-- Link input -->
            <!--input v-model="newLinkTitle" @keyup.enter="sendLink" placeholder="Title" /-->
            <input v-model="newLink" @keyup.enter="sendLink" placeholder="Paste your video link here" />
            
            <!-- Send button for mobile/desktop -->
            <button type="button" id="send-link-button" @click="sendLink" @touchstart="sendLink">Add</button>
          </div>


          <!-- LINKS -->
          <div id="links">
            <ul>
                <li v-for="(link, index) in reversedLinks" :key="index">
                    
                    <div class="row align-items-center align-items-center"> <!-- Align items vertically -->
                        
                        <div class="col-8"> 
                            <img v-if="link.avatar" :src="link.avatar" alt="User Avatar" class="chat-avatar" />
                            <strong v-if="link.userID">
                                <a :href="'/profiles/show/' + link.userID">{{ link.username }}</a>
                            </strong>
                            <strong v-else>
                                {{ link.username }} : {{ link.message }}
                            </strong>
                            <span v-if="link.timestamp">&nbsp;({{ formatTimestamp(link.timestamp) }})</span>
                        </div>

                        <div class="col-4 text-end"> <!-- Ensure text is aligned to the right -->
                            <!-- Trash icon (delete button) only visible for the user who posted the link -->
                            <span v-show="link.userID === userData.userId" class="delete-link ms-auto text-end" @click="deleteLink(link.id)">
                              <i class="bi bi-trash-fill"></i>
                            </span>
                            <!-- Trigger the modal with the PLAY button -->
                            <button v-if="link.userID && isSupportedVideoPlatform(link.URL)" @click="openVideoModal(link.URL)" class="btn btn-primary play-button">PLAY VIDEO</button>
                            <button v-if="link.userID && !isSupportedVideoPlatform(link.URL)" @click="goToUrl(link.URL)" class="btn btn-primary play-button">VIEW POST</button>
                        </div>

                    </div>

                    <div class="col-8 clip-content">
                        <a v-if="link.userID"  :href="link.URL" target="_blank">{{ link.URL }}</a>
                        <a v-else="link.userID"  :href="link.URL" target="_blank">{{ link.URL }}</a>
                    </div>
                    
                </li>
            </ul>

          </div>
        </div>


        <!-- BOOTSTRAP VIDEO PLAYER -->
        <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header" style="height: 2em;">
                <h5 class="modal-title" id="videoModalLabel">Video Player</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body d-flex" style="height: 80vh;">
                <!-- Iframe for YouTube video -->
                <iframe v-if="videoEmbedUrl" ref="videoFrame" :src="videoEmbedUrl" width="100%" frameborder="0" allowfullscreen @load="resizeIframe"></iframe>
              </div>
            </div>
          </div>
        </div>

        <!-- MESSAGES -->
        <div id="chat-panel" class="col-lg-6">
          <div id="messages">
            <ul> 
              <li v-for="(message, index) in messages" :key="index">
                <img v-if="message.avatar" :src="message.avatar" alt="User Avatar" class="chat-avatar" />
                <strong v-if="message.userID">
                  <a :href="'/profiles/show/' + message.userID">{{ message.username }}</a>
                </strong>
                <strong v-else>
                  {{ message.username }}
                </strong>&nbsp; 
                <span v-html="formatMessageWithLinks(message.message)"></span> 
                <span v-if="message.timestamp" class="float-end">&nbsp; ({{ formatTimestamp(message.timestamp) }})</span>
              </li>
            </ul>  
          </div>
          <div id="message-input" class="input-container">
            <!-- Message input -->
            <input id="chat-input" v-model="newMessage" @keyup.enter="sendMessage" @input="onMessageInput" placeholder="Type your message" />

            <!-- Display dropdown when typing '@' -->
            <ul v-if="showUserList" class="dropdown-menu">
              <li v-for="user in filteredUsers" :key="user.id" @click="selectUser(user.username)">
                {{ user.username }}
              </li>
            </ul>

            <!-- Send button for mobile/desktop -->
            <button type="button" id="send-message-button" @click="sendMessage" @touchstart="sendMessage">Send</button>
          </div>
        </div>

      </div>
  </div> 
 
</template>

<script>
// Import Socket.IO client
import { useSocket } from '../utils/websocket'; // Import the shared socket utility
import dayjs from 'dayjs';  // Import dayjs
import relativeTime from 'dayjs/plugin/relativeTime';
import Cookies from 'js-cookie';

// Extend dayjs to use the relativeTime plugin
dayjs.extend(relativeTime);

export default {
  setup() {
    const { socket, userData, fetchUserData, users, connectedUsers, fetchUsers, fetchConnectedUsers, mentionUser } = useSocket();

    // Return everything you want to access outside of setup
    return {
      socket,
      userData,
      fetchUserData,
      users,
      connectedUsers,
      fetchUsers,
      fetchConnectedUsers,
      mentionUser
    };

  },
  data() {
    return {
      newMessage: '',
      newLink: '',
      newLinkTitle: '',
      messages: [],
      links: [],
      videoEmbedUrl: '',
      blockedSites: [], 
      socket: null,
      roomID: this.getRoomCookie()?.roomID || 'WORLD',
      topicID: this.getRoomCookie()?.topicID || 'Aggressive Inline',
      roomOptions: [],
      topicOptions: [],
      isSending: false, // to avoid multiple sends
      supportsTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0, 
      filteredUsers: [], // Filtered user list to display
      showUserList: false, // Controls visibility of the user list dropdown
      atMentionDetected: false, // Tracks if @ has been typed
    };
  },
  computed: {
    reversedLinks() {
      return this.links.slice().reverse(); // Return a reversed copy of the links array
    },
  },
  methods: {
    showWelcomeMessage() {
      const welcomeMessageCookie = Cookies.get('welcomeMessageShown');
      if (!welcomeMessageCookie) {
        const welcomeMessage = {
          message: 'This chat is brought to you by Unlabelled https://unlabelled.store',  
          roomID: this.roomID,
          userID: 0, // You can set a system ID for this if needed
          username: 'Ad',
          timestamp: new Date().toISOString(),
          avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // Or provide a default system avatar
        };
        
        this.messages.push(welcomeMessage);
        Cookies.set('welcomeMessageShown', 'true', { expires: 1, path: '/' }); // Expires in 1 day
      }
    },
    formatMessageWithLinks(message) {
      // Regular expression to match URLs
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      
      // Replace URLs in the message with clickable links
      return message.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    },
    // Function to convert text-based emoticons to their Unicode emoji counterparts
    convertEmoticonsToEmoji(text) {

      const urlRegex = /(https?:\/\/[^\s]+)/g;

      const emoticonMap = {
        ':)': '\u{1F60A}',  // 😊 Smiling Face
        ':(': '\u{1F641}',  // ☹️ Frowning Face
        ':/': '\u{1F615}',   // 😕 Confused Face
        ':D': '\u{1F603}',  // 😄 Grinning Face with Big Eyes
        ';)': '\u{1F609}',  // 😉 Winking Face
        ':P': '\u{1F61B}',  // 😛 Face with Tongue
        ':O': '\u{1F62E}',  // 😮 Face with Open Mouth
        'B)': '\u{1F60E}',  // 😎 Smiling Face with Sunglasses
        'XD': '\u{1F606}',   // 😆 Laughing Face 
        '<3': '\u{1F499}'   // 💙 Blue Heart
        // Add more emoticon-to-emoji mappings as needed
      };
 
      // Split text into an array, separating URLs from other text
      const textParts = text.split(urlRegex);

      // Process each part and only replace emoticons in non-URL parts
      return textParts.map(part => {
          // If part matches the URL pattern, return it unchanged
          if (urlRegex.test(part)) {
              return part;
          }
          // Otherwise, replace emoticons with corresponding emojis
          return part.replace(/:\)|:\(|:\/|:D|;\)|:P|:O|B\)|XD|<3/g, match => emoticonMap[match] || match);
      }).join('');
    },  
    // Track user input
    onMessageInput(event) {
      const inputValue = event.target.value;
      
      // Check if '@' is typed
      if (inputValue.includes('@')) {
        this.atMentionDetected = true;

        // Extract the username being typed after '@'
        const atIndex = inputValue.lastIndexOf('@');
        const typedUsername = inputValue.substring(atIndex + 1).toLowerCase();

        if (typedUsername) {
          // Filter users whose usernames match the typed input
          this.filteredUsers = this.users.filter(user => 
            user.username.toLowerCase().startsWith(typedUsername)
          );
          this.showUserList = true;
        }
      } else {
        this.atMentionDetected = false;
        this.showUserList = false;
      }
    },
    // Select a user from the list
    selectUser(username) {
      const atIndex = this.newMessage.lastIndexOf('@');
      // Replace the typed text after '@' with the selected username
      this.newMessage = this.newMessage.substring(0, atIndex + 1) + username + ' ';
      this.showUserList = false; // Hide the user list
    },
    checkMentionedUser(mentionedUser) {
      // Check if the mentioned user exists in the current users list
      const user = this.users.find(userID => userID === mentionedUser);
      if (user) {
        // If the user exists, mention them
        //console.log("no fetch necessary");
      } else {
        // If the user doesn't exist, refetch the connected users
        console.warn(`User ${mentionedUser} not found, refetching connected users...`);
        this.fetchConnectedUsers(); // Refetch connected users
      }
    },
    detectMention(messageText) {
        const mentionPattern = /@(\w+)/g; // Simple regex to find mentions, adjust as needed
        const mentionedUsers = [];

        let match;

        while ((match = mentionPattern.exec(messageText)) !== null) {
            mentionedUsers.push(match[1]); // Add the username (or userID if you change the pattern)
        }

        //console.log(mentionedUsers);

        return mentionedUsers.length > 0 ? mentionedUsers : null; // Return an array of mentions or null
    },
    findUserByUsername(username) {
        return this.users.find(user => user.username === username); // Assuming 'users' is an array of user objects
    },
    sendMessage() {
      if (this.isSending) return;

      if (!this.newMessage.trim()) {
        console.error('Message text is empty.');
        return;
      }

      let emojiMessage = this.convertEmoticonsToEmoji(this.newMessage);

      if (!this.userData.userId){
        const systemMessage = {
              message: 'Log in or Register to send messages. https://unlabelled-girls.com/Security/login?BackURL=chat-rooms',
              roomID: this.roomID,
              topicID: this.topicID,
              userID: null, // System messages don't have a userID
              username: 'System',
              timestamp: null,
              avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // No avatar for system messages
            };

            this.messages.push(systemMessage);
            this.newMessage = '';
            return;
      }

      const messageData = {
        message: emojiMessage,
        roomID: this.roomID,
        topicID: this.topicID,
        userID: this.userData.userId,
        username: this.userData.username,
        timestamp: new Date().toISOString(),
        avatar: this.userData.avatar
      };

      this.isSending = true; // Set flag to true to prevent multiple sends

      fetch('/chat/addMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.status === 'success') {

            const mentionedUsers = this.detectMention(emojiMessage);
            this.socket.emit('sendMessage', messageData);

            // Emit toast notifications for mentioned users
            if (mentionedUsers) {

                mentionedUsers.forEach(username => this.checkMentionedUser(username)); // Check each mentioned user

                mentionedUsers.forEach(mentionedUser => {
                    const user = this.findUserByUsername(mentionedUser); // Implement this function
                    if (user) {
                        //console.log(user.id);
                        const encodedURL = 'chat/router/' + encodeURIComponent(this.roomID) + '/' + encodeURIComponent(this.topicID);
                        console.log(encodedURL);
                        this.mentionUser(user.id, emojiMessage, encodedURL);
                    } 
                });
            }

            this.newMessage = '';
            this.scrollToBottom();
            this.showUserList = false;

            // Scroll to top or refresh the layout after sending the message
            window.scrollTo(0, 0);  // Ensures you scroll to the top after message sent
            document.activeElement.blur(); // Closes the virtual keyboard on mobile

          } else {
            console.error(data.message);
          }
          this.isSending = false; // Reset flag after sending
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
          this.isSending = false;
        });
    },
    fetchMessages() {
      fetch(`/chat/messages/${encodeURIComponent(this.roomID)}/${encodeURIComponent(this.topicID)}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            this.messages = data.messages;
            this.showWelcomeMessage(); 
            this.scrollToBottom();
          } else {
            console.error('Error fetching messages:', data.message);
          }
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
        });
    },
    sendLink() {
      if (this.isSending) return;

      // Regular expression to validate URL format
      const urlPattern = /^(https?:\/\/)?((www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/[^\s]*)?$/;

      if (!this.newLink.trim() /*|| !this.newLinkTitle.trim()*/) {
        console.error('Link text is empty.');
        return;
      }


      /* CHECK IF USER HAS ID */

      if (!this.userData.userId){
        const systemMessage = {
              URL: 'https://unlabelled-girls.com/Security/login?BackURL=chat-rooms',
              message: 'Log in or Register to post links.', 
              roomID: this.roomID,
              topicID: this.topicID,
              userID: null, // System messages don't have a userID
              username: 'System',
              timestamp: null,
              avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // No avatar for system messages
            };

            this.links.push(systemMessage);
            this.newLink = '';
            return;
      }

      /* CHECK FOR VALID URL */

      if (!urlPattern.test(this.newLink.trim())) {
        console.error('Invalid URL format.');
        
        // Send system message to notify user of invalid link
        const systemMessage = {
          message: 'The link you provided is not valid.',
          roomID: this.roomID,
          topicID: this.topicID,
          userID: null, // System messages don't have a userID
          username: 'System',
          timestamp: null,
          avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // No avatar for system messages
        };

        this.links.push(systemMessage);
        this.newLink = '';
        return;
      }

      /* CHECKS FOR RESTRICTED LINKS */

      const isBlocked = this.blockedSites.some(site => this.newLink.includes(site.URL));

      if (isBlocked) {
        // Send a system message indicating that the link is not allowed
        const systemMessage = {
          message: 'The link you attempted to share is not allowed.',
          roomID: this.roomID,
          topicID: this.topicID,
          userID: null, // Use a special ID for system messages
          username: 'System',
          timestamp: null,
          avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg' // Optionally add a system avatar
        };

        this.links.push(systemMessage);
        this.newLink = ''; // Clear the link input
        this.scrollToBottom();
        return; // Exit if the link is blocked
      }
      
      /* CHECK FOR RUMBLE VIDEO (NON-EMBED) */
        if (this.isRumble(this.newLink) && !this.isRumbleEmbed(this.newLink)) {
            // Send a system message indicating that the Rumble link is not an embed code
            const systemMessage = {
                message: 'Please submit the embed code for Rumble videos, not the regular link.',
                roomID: this.roomID,
                topicID: this.topicID,
                userID: null, // Use a special ID for system messages
                username: 'System',
                timestamp: null,
                avatar: '/assets/Avatar/Avatar-UNL-Black-20px.jpg'
            };

            this.links.push(systemMessage);
            this.newLink = ''; // Clear the link input
            this.scrollToBottom();
            return; // Exit if the Rumble link is not an embed
        } 

      /* ALL CHECKS DONE : SENDING LINK */

      // Prepend https:// if the link doesn't start with http:// or https://
      if (!this.newLink.startsWith('http://') && !this.newLink.startsWith('https://')) {
        this.newLink = 'https://' + this.newLink;
      }

      const linkData = {
        URL: this.newLink,
        roomID: this.roomID,
        topicID: this.topicID,
        userID: this.userData.userId,
        username: this.userData.username,
        timestamp: new Date().toISOString(),
        avatar: this.userData.avatar
      };

      this.isSending = true; // Set flag to true to prevent multiple sends

      fetch('/chat/addLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(linkData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.status === 'success') {
            this.socket.emit('sendLink', linkData);
            this.newLink = '';
            //this.newLinkTitle = '';
            this.scrollToBottom();

            // Scroll to top or refresh the layout after sending the message
            window.scrollTo(0, 0);  // Ensures you scroll to the top after message sent
            document.activeElement.blur(); // Closes the virtual keyboard on mobile

          } else {
            console.error(data.message);
          }
          this.isSending = false; // Reset flag after sending
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
          this.isSending = false;
        });
    },
    deleteLink(linkID) {
      if (!confirm("Are you sure you want to delete this link?")) {
        return; // Cancel the deletion if the user doesn't confirm
      }

      // Send a request to the backend to delete the link
      fetch(`/chat/deleteLink/${linkID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': Cookies.get('XSRF-TOKEN'), // If CSRF protection is enabled
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete link');
        }
        return response.json();
      })
      .then(data => {
        if (data.status === 'success') {
          // Remove the link from the local links array
          this.links = this.links.filter(link => link.id !== linkID);
        } else {
          console.error('Error deleting link:', data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    },
    fetchBlockedSites() {
      try {
        fetch('/chat/blockedSites/') // Ensure you have this endpoint set up
          .then(response => response.json())
          .then(data => {
            this.blockedSites = data;
        })
      } catch (error) {
        console.error('Error fetching blocked sites:', error);
      }
    },
    fetchLinks() {
      fetch(`/chat/links/${encodeURIComponent(this.roomID)}/${encodeURIComponent(this.topicID)}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            this.links = data.links;
            this.scrollToBottom();
          } else {
            console.error('Error fetching links:', data.link);
          }
        })
        .catch(error => {
          console.error('Error in fetch operation:', error);
        });
    },
    // Unified function to check if the video URL is from a supported platform
    isSupportedVideoPlatform(url) {
        return this.isYouTube(url) || this.isVimeo(url) || this.isRumble(url) || this.isFacebook(url);
    },

    // Extract video ID based on platform
    extractVideoId(url) {
        if (this.isYouTube(url)) {
            return this.extractYouTubeId(url);
        } else if (this.isRumble(url)) {
            return this.extractRumbleId(url);
        } else if (this.isVimeo(url)) {
            return this.extractVimeoId(url);
        } else if (this.isFacebook(url)) {
            return (
                    this.extractFacebookVideoId(url) || 
                    this.extractFacebookWatchId(url) || 
                    this.extractFacebookReelId(url)
                   ); 
        }
        return null; // No valid video ID
    },

    // Open the video modal based on platform
    openVideoModal(url) {
        const videoId = this.extractVideoId(url);

        if (videoId) {
            if (this.isYouTube(url)) {
                this.videoEmbedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
            } else if (this.isRumble(url)) {
                if (this.isRumbleEmbed(url)) {
                    this.videoEmbedUrl = url.includes('?') ? `${url}&autoplay=1` : `${url}?autoplay=1`;
                } else {
                    alert("Submit the embed code for Rumble videos.");
                    return; // Exit for non-embed Rumble links
                }
            } else if (this.isVimeo(url)) {
                this.videoEmbedUrl = `https://player.vimeo.com/video/${videoId}?autoplay=1`;
            } else if (this.isFacebook(url)) {
                  // Embed the full Facebook Watch URL for embedding
                  const cleanUrl = url.includes('fb.watch') ? url : `https://www.facebook.com/video.php?v=${videoId}`;
                  this.videoEmbedUrl = `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(cleanUrl)}&autoplay=1`;
            }
            // Show modal
            this.$nextTick(() => {
                const modalElement = document.getElementById('videoModal');
                const modal = new bootstrap.Modal(modalElement);
                modal.show();
            });
        }
    },
 
    resizeIframe() {
      const iframe = this.$refs.videoFrame;
      iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    },

    goToUrl(url) {
        window.open(url, '_blank');
    },

    // Extract YouTube video ID (including YouTube Shorts)
    extractYouTubeId(url) {
        const match = url.match(/(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match ? match[1] : null;
    },

    // Check if URL is from YouTube (including Shorts)
    isYouTube(url) {
        return /youtube\.com\/(watch|embed|shorts)|youtu\.be/.test(url);
    },

    // Extract Rumble video ID
    extractRumbleId(url) {
        const match = url.match(/rumble\.com\/(?:v|embed)\/([a-zA-Z0-9_-]+)/) || url.match(/rumble\.com\/([a-zA-Z0-9_-]+)\.html/);
        return match ? match[1].substring(0, 7) : null;
    },

    // Check if URL is from Rumble
    isRumble(url) {
        return /rumble\.com/.test(url);
    },

    // Check if it's an embed link from Rumble
    isRumbleEmbed(url) {
        return /rumble\.com\/embed\//.test(url);
    },

    // Extract Vimeo video ID
    extractVimeoId(url) {
        const match = url.match(/(?:vimeo\.com\/(?:video\/)?|vimeo\.com\/)([0-9]+)/);
        return match ? match[1] : null;
    },

    // Check if URL is from Vimeo
    isVimeo(url) {
        return /vimeo\.com/.test(url);
    },
    extractFacebookVideoId(url) {
        const match = url.match(/[?&]v=([0-9]+)/);
        return match ? match[1] : null;
    },

    // Extract Facebook video ID from Reel URLs
    extractFacebookReelId(url) {
        const match = url.match(/facebook\.com\/reel\/([0-9]+)/);
        return match ? match[1] : null;
    },

    // Extract Facebook video ID from Watch URLs (if needed)
    extractFacebookWatchId(url) {
        const match = url.match(/\/watch\/\?v=([0-9]+)/) || url.match(/fb\.watch\/([a-zA-Z0-9]+)/);
        return match ? match[1] : null;
    },
    // Check if URL is from Facebook
    isFacebook(url) {
        return /facebook\.com\/(watch|video|plugins|reel)/.test(url) || /fb\.watch/.test(url);
    },
    // Check if it's an embed link from Facebook
    isFacebookEmbed(url) {
        return /facebook\.com\/plugins\/video\.php/.test(url);
    },
    formatTimestamp(timestamp) {
      // Your timestamp formatting logic
      return new Date(timestamp).toLocaleString();
    },
    showChat() {
      this.$nextTick(() => {
        document.querySelector('#chat-panel').style.display = 'block'; // Use vanilla JS
        document.querySelector('#links-panel').style.display = 'none';
        this.setPanelCookie('Messages');
      });
    },
    showLinks() {
      this.$nextTick(() => {
        document.querySelector('#chat-panel').style.display = 'none';
        document.querySelector('#links-panel').style.display = 'block';
        this.setPanelCookie('Links');
      });
    },
    fetchRoomOptions() {
      fetch('/chat/roomOptions/')
        .then(response => response.json())
        .then(data => {
          this.roomOptions = data;
        })
        .catch(error => console.error('Error fetching room options:', error));
    },
    isRegion(room) {
      // Check if the room is uppercase
      return room === room.toUpperCase();
    },
    fetchTopicOptions() {
      fetch('/chat/topicOptions/')
        .then(response => response.json())
        .then(data => {
          this.topicOptions = data;
        })
        .catch(error => console.error('Error fetching topic options:', error));
    },
    // Function to set the room cookie using js-cookie
    setRoomCookie(roomID, topicID) {
      Cookies.set('chatRoom', JSON.stringify({ roomID, topicID }), { expires: 7, path: '/' }); // Expires in 7 days
    },
    // Function to get the room cookie using js-cookie
    getRoomCookie() {
      const cookieValue = Cookies.get('chatRoom'); // Get the single cookie storing both roomID and topicID
      if (cookieValue) {
        try {
          // Parse the JSON string to extract roomID and topicID
          return JSON.parse(cookieValue);
        } catch (e) {
          console.error('Error parsing chatRoom cookie:', e);
          return null;
        }
      }
      return null;
    },
    // Function to change room and update the cookie
    changeRoom(newRoomID) {
      this.roomID = newRoomID;
      this.setRoomCookie(this.roomID, this.topicID);
      this.fetchLinks();
      this.fetchMessages();
    },

    // Function to change topic and update the cookie
    changeTopic(newTopicID) {
      this.topicID = newTopicID;
      this.setRoomCookie(this.roomID, this.topicID);
      this.fetchLinks();
      this.fetchMessages();
    },

    // Function to set the room cookie using js-cookie
    setPanelCookie(panel) {
      Cookies.set('chatPanel', panel, { expires: 7, path: '/' }); // Expires in 7 days
    },
    // Function to get the room cookie using js-cookie
    getPanelCookie() {
      return Cookies.get('chatPanel'); 
    },
    scrollToBottom() {
      this.$nextTick(() => {
        
          const linksContainer = document.querySelector('#links');
          const chatContainer = document.querySelector('#messages');
      
        if (this.getPanelCookie() === 'Links'){
          linksContainer.scrollTop = linksContainer.scrollHeight;
        } 

        if (this.getPanelCookie() === 'Messages'){
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
        
      });
    },
    // Add formatTimestamp function to format the timestamps
    formatTimestamp(timestamp) {
      return dayjs(timestamp).fromNow(); // Use dayjs to format the timestamp as "X minutes ago"
    },
    checkWidth() {
      const linksPanel = document.querySelector('#links-panel');
      const chatPanel = document.querySelector('#chat-panel');
      const windowWidth = window.innerWidth;

      if (windowWidth < 992) { // Adjust this value based on when you want to hide the links panel
        if (this.getPanelCookie() === 'Links'){  
          linksPanel.style.display = 'block';
          chatPanel.style.display = 'none'; // Show chat panel by default
        } else {  
          linksPanel.style.display = 'none';
          chatPanel.style.display = 'block'; // Show chat panel by default
        }
      } else {
        linksPanel.style.display = 'block';
        chatPanel.style.display = 'block'; // Ensure chat panel is also shown
      }

      document.documentElement.style.overflowY = 'hidden';

    },
    handleInputFocus() {
      // When the keyboard opens, restrict overflow to prevent resizing issues
      document.documentElement.style.overflowY = 'hidden';
    },
    handleInputBlur() {
      // When input focus is lost, remove the overflow restriction
      document.documentElement.style.overflowY = '';
    }, 
    addEventListeners() {
      const sendButton = document.querySelector('#send-message-button');
      if (this.supportsTouch) {
        sendButton.addEventListener('touchstart', this.sendMessage);
      } else {
        sendButton.addEventListener('click', this.sendMessage);
      }

      const sendButtonLink = document.querySelector('#send-link-button');
      if (this.supportsTouch) {
        sendButton.addEventListener('touchstart', this.sendLink);
      } else {
        sendButton.addEventListener('click', this.sendLink);
      }

      // Listen for the modal close event
      const modalElement = document.getElementById('videoModal');
      modalElement.addEventListener('hidden.bs.modal', () => {
        // Stop the video by resetting the src
        this.videoEmbedUrl = '';
      });
    },

  },
  mounted() {

    this.fetchUserData() 
      .then(() => {
        this.fetchConnectedUsers();
      }); 

    this.fetchRoomOptions();  // Fetch room options
    this.fetchTopicOptions(); // Fetch topic options

    this.handleInputFocus();
    const inputField = document.querySelector('input');
    inputField.addEventListener('focus', this.handleInputFocus);
    inputField.addEventListener('blur', this.handleInputBlur);
    
    this.fetchBlockedSites();

    // Retrieve the room from the cookie if it exists
    const savedRoom = this.getRoomCookie();
    if (savedRoom) {
      this.roomID = savedRoom.roomID; // Extract roomID
      this.topicID = savedRoom.topicID; // Extract topicID
    } else {
      console.log('No saved room data found in the cookie.');
    }

    this.fetchMessages();
    this.fetchLinks();

    // Check width on mount
    this.checkWidth();

    // Add resize event listener
    window.addEventListener('resize', this.checkWidth);

    this.socket.on('message', (data) => {
      if (data.roomID === this.roomID && data.topicID === this.topicID) {
        this.messages.push(data);
      }
    });

    this.socket.on('URL', (data) => {
      if (data.roomID === this.roomID && data.topicID === this.topicID) {
        if(data.userID === this.userData.userId){
          this.fetchLinks();
        }
        else {
          this.links.push(data);
        }
      }
    });

    // Call method to add the appropriate event listeners
    this.addEventListeners();

  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }

    // Clean up event listeners when the component is destroyed
    const inputField = document.querySelector('input');
    inputField.removeEventListener('focus', this.handleInputFocus);
    inputField.removeEventListener('blur', this.handleInputBlur);

    // Clean up event listeners when the component is destroyed
    const sendButton = document.querySelector('#send-message-button');
    if (this.supportsTouch) {
      sendButton.removeEventListener('touchstart', this.sendMessage);
    } else {
      sendButton.removeEventListener('click', this.sendMessage);
    }

    const sendButtonLink = document.querySelector('#send-link-button');
    if (this.supportsTouch) {
      sendButton.removeEventListener('touchstart', this.sendLink);
    } else {
      sendButton.removeEventListener('click', this.sendLink);
    }
  }
}; 

</script>